.header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.logo {
  width: 80px;
}

.badge {
  min-width: 100px;
}
